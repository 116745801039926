import { useEffect, useState } from "react";
import Toggle from "../../components/toggle";
import { ContainerParent } from "../../components/container/ContainerParent";
import { useTheme } from "styled-components";
import { useBotContext } from "../../context/BotContext";
import { groupApi } from "../../services/groups";
import { TelegramGroups } from "../../types/telegram-groups";
import { Header } from "../../components/header";
import { useAuth } from "../../context/AuthContext";
import { SubContainer } from "../../components/container";
import {
  TextLarge,
  TextMedium,
  TextMinimum,
} from "../../components/texts/TextStyles";
import Button from "../../components/buttons";
import { useNavigate } from "react-router-dom";
import PriceModal, { Values } from "../../components/modal/price";
import { usePricesContext } from "../../context/PricesContext";
import { toast } from "react-toastify";

export const ManagerGroups = () => {
  const theme = useTheme();
  const [isModalPriceOpen, setIsModalPriceOpen] = useState({
    isOpen: false,
    groupId: 0,
  });
  const navigate = useNavigate();
  const { isActive, startBot, stopBot } = useBotContext();
  const [grupos, setGrupos] = useState<TelegramGroups[]>([]);
  const { addPriceItem, loadingSavePrice } = usePricesContext();
  const [formActive, setFormActive] = useState(false);
  const { user } = useAuth();

  useEffect(() => {
    (async () => {
      try {
        const grupos = await groupApi.getGroups();
        setGrupos(grupos);
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);

  const setTempGroup = async ({
    groupId,
    tempValue,
  }: {
    groupId: number;
    tempValue: boolean;
  }) => {
    console.log({
      groupId,
      tempValue,
    });
    try {
      await groupApi.setStatusTempGroup({
        groupId,
        tempValue,
      });
      const newGroups = grupos.map((group) => {
        if (group.groupInfor.groupId === groupId) {
          // Clona o grupo e altera o valor de "temp"
          return {
            ...group,
            groupInfor: {
              ...group.groupInfor,
              temp: tempValue,
            },
          };
        }
        return group; // Retorna o grupo sem alterações se o ID não corresponder
      });
      setGrupos(newGroups);
    } catch (error) {
      toast.error("Ocorreu um erro ao tentar transformar grupo em temporário");
    }
  };

  const setIsActiveGroup = async ({
    groupId,
    tempValue,
  }: {
    groupId: number;
    tempValue: boolean;
  }) => {
    console.log({
      groupId,
      tempValue,
    });
    try {
      await groupApi.setIsActiveGroup({
        groupId,
        tempValue,
      });
      const newGroups = grupos.map((group) => {
        if (group.groupInfor.groupId === groupId) {
          // Clona o grupo e altera o valor de "temp"
          return {
            ...group,
            groupInfor: {
              ...group.groupInfor,
              isActive: tempValue,
            },
          };
        }
        return group; // Retorna o grupo sem alterações se o ID não corresponder
      });
      setGrupos(newGroups);
    } catch (error) {
      toast.error("Ocorreu um erro ao tentar transformar grupo em temporário");
    }
  };

  return (
    <ContainerParent
      style={{
        backgroundColor: theme.subContainerBackground,
        display: "flex",
        flexDirection: "column",
        padding: 18,
      }}
    >
      <Header username={user?.displayName?.toString() || ""} />
      <SubContainer
        style={{
          flex: 1,
          width: "100%",
          marginTop: 24,
          alignItems: "flex-start",
          justifyContent: "flex-start",
          boxSizing: "border-box",
          gap: 24,
        }}
      >
        <TextLarge style={{ fontWeight: "800" }}>
          Gerencie seus grupos VIP do telegram
        </TextLarge>
        <SubContainer style={{ width: "100%", marginBottom: 24 }}>
          <div
            style={{
              flexDirection: "row",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <span>Ativar/Desativar Bot</span>
            <Toggle
              initialState={isActive}
              onToggle={() => {
                if (!isActive) {
                  startBot();
                } else {
                  stopBot();
                }
              }}
            />
          </div>
        </SubContainer>
        <div
          style={{
            display: "flex",
            flex: 1,
            textAlign: "start",
            flexWrap: "wrap",
            gap: 12,
            overflow: "auto",
            height: "auto",
            backgroundColor: "transparent",
          }}
        >
          {grupos &&
            grupos?.map((item, index) => {
              console.log({ ...item });
              return (
                <SubContainer style={{ gap: 12, flex: 1 }} key={index}>
                  <div>
                    <TextMedium
                      style={{
                        fontWeight: 800,
                        color: theme.inputFocusBorder,
                        marginBottom: 12,
                      }}
                    >
                      {item.groupInfor.title}
                    </TextMedium>
                    <TextMinimum style={{ marginBottom: 14 }}>
                      {item.groupInfor.groupId}
                    </TextMinimum>
                  </div>
                  <div>
                    <Button
                      style={{ width: "100%", marginBottom: 12 }}
                      title={"Assinantes"}
                      onClick={() => navigate("/subscriptions")}
                    />
                    <Button
                      style={{ width: "100%" }}
                      title={"Preços"}
                      onClick={() =>
                        setIsModalPriceOpen({
                          isOpen: true,
                          groupId: item.groupInfor.groupId,
                        })
                      }
                    />
                    <div style={{ display: "flex", gap: 16, marginTop: 24 }}>
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",

                          flexDirection: "column",
                        }}
                      >
                        <TextMinimum style={{ marginBottom: 14 }}>
                          Acesso temporário?
                        </TextMinimum>
                        <Toggle
                          initialState={item?.groupInfor?.temp}
                          onToggle={() =>
                            setTempGroup({
                              groupId: item.groupInfor.groupId,
                              tempValue: !item?.groupInfor?.temp,
                            })
                          }
                        />
                      </div>
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          flexDirection: "column",
                        }}
                      >
                        <TextMinimum style={{ marginBottom: 14 }}>
                          Habilitado
                        </TextMinimum>
                        <Toggle
                          initialState={item?.groupInfor?.isActive}
                          onToggle={() =>
                            setIsActiveGroup({
                              groupId: item.groupInfor.groupId,
                              tempValue: !item?.groupInfor?.isActive,
                            })
                          }
                        />
                      </div>
                    </div>
                  </div>
                </SubContainer>
              );
            })}
        </div>
      </SubContainer>
      <PriceModal
        formActive={formActive}
        setFormActive={setFormActive}
        loadingSavePrice={loadingSavePrice}
        isOpen={isModalPriceOpen.isOpen}
        onClose={() => setIsModalPriceOpen({ isOpen: false, groupId: 0 })}
        priceDetails={{
          groupId: Number(isModalPriceOpen.groupId),
        }}
        onSave={(data: Values) => {
          addPriceItem(data, isModalPriceOpen.groupId);
          setFormActive(false);
        }}
      />
    </ContainerParent>
  );
};
