import api from "../conections/axios";
import { TelegramGroups } from "../types/telegram-groups";

export const groupApi = {
  async getGroups(): Promise<TelegramGroups[]> {
    try {
      const response = await api.get(`/grupos`);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  async getStatusTempGroup({ groupId }: { groupId: number }): Promise<Boolean> {
    try {
      const response = await api.get(`/grupos/get-temp?groupId=${groupId}`);
      return response.data?.temp;
    } catch (error) {
      throw error;
    }
  },
  async setStatusTempGroup({
    groupId,
    tempValue,
  }: {
    groupId: number;
    tempValue: boolean;
  }): Promise<Boolean> {
    try {
      const response = await api.post(`/grupos/update-temp`, {
        tempValue,
        groupId,
      });
      return response.data?.temp;
    } catch (error) {
      throw error;
    }
  },
  async setIsActiveGroup({
    groupId,
    tempValue,
  }: {
    groupId: number;
    tempValue: boolean;
  }): Promise<Boolean> {
    try {
      const response = await api.post(`/grupos/update-active`, {
        tempValue,
        groupId,
      });
      return response.data?.temp;
    } catch (error) {
      throw error;
    }
  },
};
